.modalContainer {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 50%;
  transform: translate(-50%, 0);
}

.modal {
  background: #586a91;
  border: 1px solid #000000;
  transition: 1.1s ease-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.modal .off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(black, 0.2);
}
.modal h2 {
  border-bottom: 2px solid #000000;
  padding: 1rem;
  margin: 0;
}
.modal .content {
  padding: 1rem;
}

.modal .actions {
  border-top: 2px solid #000000;
  background: #528e6f;
  padding: 0.5rem 1rem;
}