.App {
  max-width: 860px;
  margin: 40px auto;
}
button {
  background: none;
  border: 2px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
button:hover {
  background: #0073ff;
  color: #fff;
}

/* grid styling */
.hexagon {
  cursor: pointer;
  fill: transparent;
}
.tile-top :hover {
  fill: #0073ff;
}

/* .hexagon polygon {
  fill: #8ab9f1;
} */
/* .hexagon polygon:hover {
  fill: #0073ff;
} */

/* other styling */
/* svg {
  width: 100%;
  flex: 1;
} */